import React from 'react';
import { 
    List, 
    Datagrid, 
    TextField, 
    ReferenceField, 
    Pagination,
    UrlField,
    Edit,
    SimpleForm,
    TextInput,ReferenceInput,SelectInput} from 'react-admin';

//import {FlagFormIterator} from './FlagFormIterator'

import { makeStyles } from '@material-ui/core/styles';

const DeploymentPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

const UrlField2 = ({ record, source }) => <a href={record[source]}>Deploy Config</a>;

export const DeploymentList = props => (
    <List {...props} bulkActionButtons={false} pagination={<DeploymentPagination />}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="workspace" />
            <UrlField2 source="deployUrl" label="Deploy"/>
        </Datagrid>
    </List>
);