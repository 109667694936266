import React from 'react';
import { 
    TabbedForm,
    FormTab,
    List, 
    Datagrid, 
    TextField, 
    ReferenceField, 
    EditButton,
    SimpleForm,
    SelectInput,
    Filter,
    Edit,
    TextInput,
    ReferenceInput,
    Create,
    ChipField,
    SingleFieldList,
    ArrayField,
    ArrayInput,
    SimpleFormIterator,
    NumberInput,
    BooleanField,
    BooleanInput,
    ReferenceArrayInput,
    SelectArrayInput,
    AutocompleteArrayInput,
    AutocompleteInput,
    Pagination} from 'react-admin';

//import {FlagFormIterator} from './FlagFormIterator'

import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const FlagPagination = props => <Pagination rowsPerPageOptions={[10,98,99]} {...props} />;

var workspaceFilterValue;

const CreateFlagButton = (props) => (
    <Button
        component={Link}
        to={{
            pathname: '/flags/create',
            state: { record: { workspaceId: workspaceFilterValue  }}
        }}
    >
        Create Flag
    </Button>
);


const handleStateChange = (val) =>
    workspaceFilterValue = val;

const FlagFilter = (props) => (
    <Filter {...props} >
        <ReferenceInput label="Workspace" source="workspaceId" reference="workspaces" alwaysOn onChange={event => handleStateChange(event.target.value)}  >
            <SelectInput optionText="workspace" />
        </ReferenceInput>
    </Filter>
);

export const FlagList = props => (
    <List filters={<FlagFilter />} {...props} bulkActionButtons={false} perPage={99} actions={<CreateFlagButton />} >
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <BooleanField source="active" />
            <BooleanField source="defaultValue" />
            <BooleanField source="hasUndeployedChange" />
            <TextField source="type" />
            <ArrayField source="cohorts"><SingleFieldList><ChipField source="rolloutValue" /></SingleFieldList></ArrayField>
            <ReferenceField source="workspaceId" reference="workspaces">
                <TextField source="workspace" />
            </ReferenceField>
        </Datagrid>
    </List>
);



const required = (message = 'Required') =>
    value => value || value == 0 ? undefined : message;
const number = (message = 'Must be a number') =>
    value => value && isNaN(Number(value)) ? message : undefined;
const minValue = (min, message = 'Too small') =>
    value => value && value < min ? message : undefined;
const maxValue = (max, message = 'Too big') =>
    value => value && value > max ? message : undefined;

const validateRollout = [required(), number(), minValue(0), maxValue(100)];

const validateName = [required()];

const validateFlagCreation = (values) => {
    const errors = {};

    if (!values.name) {
        errors.name = ['Flag name is required'];
    }
    return errors
};

export const FlagEdit = props => {
    return (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="flagId" />
            <TextInput source="workspaceId" disabled/>
            <ReferenceInput label="WorkspaceName" source="workspaceId" reference="workspaces" >
                <SelectInput source="workspace" optionText="workspace" disabled/>
            </ReferenceInput>
            <TextInput source="name" validate={validateName}/>
            <BooleanInput label="Active" source="active" />
            <SelectInput source="type" choices={[
                { id: 'boolean', name: 'boolean' },
                { id: 'text', name: 'text' }
            ]} />
            <SelectInput source="defaultValue" label="Default Flag Value" choices={[
                { id: true, name: 'Enabled' },{ id: false, name: 'Disabled' }
            ]} />
            <ArrayInput source="cohorts" label="Rollout by Cohort:"><SimpleFormIterator>
            <NumberInput source="rolloutValue" label="Rollout Percentage" inputProps={{min: 0, max: 100}} validate={validateRollout}/>
            <SelectInput source="stickinessProperty" label="Stickiness Property" choices={[
                { id: 'ffUserId', name: 'Feature Flag User Id' },
                { id: 'appUserId', name: 'Application User Id' }
            ]} />
            <ArrayInput source="cohortCriteria" label="Cohort Criteria:" ><SimpleFormIterator><TextInput source="requiredFieldName" label="Field Name" style={{ display: 'inline', float: 'left', marginLeft: '20px', marginTop: '20px' }}/>
            <TextInput source="requiredFieldValues" label="Allowed Field Value(s)" style={{ display: 'flex', float: 'left', marginLeft: '20px', marginTop: '20px'}}/></SimpleFormIterator></ArrayInput>
            </SimpleFormIterator></ArrayInput>
        </SimpleForm>

    </Edit>
)
};

export const FlagCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="workspaceId" disabled/>
            <ReferenceField label="WorkspaceName" source="workspaceId" reference="workspaces">
                <TextField source="workspace" />
            </ReferenceField>
            <TextInput source="name" validate={validateName}/>
            <TextInput source="flagId" label="Flag Id" validate={validateName}/>
            <BooleanInput label="Active" source="active" defaultValue={true}/>
            <SelectInput source="type" defaultValue={"boolean"} choices={[
                { id: 'boolean', name: 'boolean' },
                { id: 'text', name: 'text' }
            ]} />

            <SelectInput source="defaultValue" label="Default Flag Value" defaultValue={false} choices={[
                { id: true, name: 'Enabled' },{ id: false, name: 'Disabled' }
            ]} />
            <ArrayInput source="cohorts" label="Rollout by Cohort:" defaultValue={[{ rolloutValue: 100, stickinessProperty: "ffUserId"}]}>
            <SimpleFormIterator>
            <NumberInput source="rolloutValue" label="Rollout Percentage" inputProps={{min: 0, max: 100}} validate={validateRollout}/>
            <SelectInput source="stickinessProperty" label="Stickiness Property" choices={[
                { id: 'ffUserId', name: 'Feature Flag User Id' },
                { id: 'appUserId', name: 'Application User Id' }
            ]} />
            <ArrayInput source="cohortCriteria" label="Cohort Criteria:" >
            <SimpleFormIterator>


                <TextInput source="requiredFieldName" label="Field Name" style={{ display: 'inline', float: 'left', marginLeft: '20px', marginTop: '20px' }}/>
                <TextInput source="requiredFieldValues" label="Allowed Field Value(s)" style={{ display: 'flex', float: 'left', marginLeft: '20px', marginTop: '20px'}}/></SimpleFormIterator></ArrayInput>
            </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);