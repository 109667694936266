import React from 'react';



import { Admin, Resource, ListGuesser,EditGuesser} from 'react-admin';
//import { PostList,PostEdit,PostCreate } from './posts';
import {FlagList, FlagEdit, FlagCreate} from './flags'
//import { UserList } from './users';///>

import {WorkspaceList,WorkspaceEdit} from './workspaces'

import {DeploymentList} from './deployments'

//<Resource name="posts" list={PostList} edit={PostEdit} create={PostCreate}/>
import jsonServerProvider from 'ra-data-json-server';

import { createMuiTheme } from '@material-ui/core/styles';

//import customRoutes from './customRoutes';


const theme = createMuiTheme({
  palette: {
    type: 'light', // Switching the dark mode on is a single property value change.
  },
});

const dataProvider = jsonServerProvider('http://wm-feature-flag-api.us-east-1.elasticbeanstalk.com');

//const dataProvider = jsonServerProvider('http://localhost:5000');

const App = () => (
    <Admin dataProvider={dataProvider}>
    	<Resource name="flags" list={FlagList} edit={FlagEdit} create={FlagCreate}/>
    	<Resource name="workspaces" list={WorkspaceList} edit={WorkspaceEdit}/>
    	<Resource name="deployments" list={DeploymentList}/>
    </Admin>
);

export default App;