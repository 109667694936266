import React from 'react';
import { 
    List, 
    Datagrid, 
    TextField, 
    ReferenceField, 
    Pagination,
    UrlField,
    Edit,
    SimpleForm,
    TextInput,ReferenceInput,SelectInput} from 'react-admin';

//import {FlagFormIterator} from './FlagFormIterator'

import { makeStyles } from '@material-ui/core/styles';

const WorkspacePagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

const UrlField2 = ({ record, source }) => <a href={record[source]}  target='_blank' rel="noopener noreferrer">Preview File</a>;
const UrlField3 = ({ record, source }) => <a href={record[source]}  target='_blank' rel="noopener noreferrer">Show Diff</a>;

export const WorkspaceList = props => (
    <List {...props} bulkActionButtons={false} pagination={<WorkspacePagination />}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="workspace" />
            <UrlField2 source="previewFullUrl" label="Preview Full File"/>
            <UrlField3 source="showDiff" label="Show local vs deployed"/>
            <UrlField source="fullConfigFileUrl" label="Deployed Full File" />
            <UrlField source="fallbackConfigUrl" label="Deployed Fallback File"/>
        </Datagrid>
    </List>
);

export const WorkspaceEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <TextInput source="workspace" disabled/>
            <ReferenceInput label="Replace with config file from" source="deployFromWorkspaceId" reference="workspaces" >
                <SelectInput source="name" optionText="workspace"/>
            </ReferenceInput>
            <TextField label="Caution: saving this will delete all current flags and replace with the deployed config file from the chosen workspace."/>
        </SimpleForm>
    </Edit>
);

